const throttle = (callback, limit) => {
	let wait = false;

	return function () {
		if (!wait) {
			wait = true;
			setTimeout(() => {
				wait = false;
				callback.apply(this, arguments);
			}, limit || 1);
		}
	};
};

/**
 * getGeom - returns the geom object specified in section 5.4 https://www.iab.com/wp-content/uploads/2014/08/SafeFrames_v1.1_final.pdf
 * @param {Element} node - the iframe node
 */
const getGeom = (node) => {
	const nodeRect = node.getBoundingClientRect();
	const style = window.document.defaultView.getComputedStyle(node);
	const scrollX = window.pageXOffset;
	const scrollY = window.pageYOffset;

	const winHeight = window.innerHeight || 0;
	const winWidth = window.innerWidth || 0;
	const winTop = window.screenY || window.screenTop || 0;
	const winBottom = winTop + winHeight;
	const winLeft = window.screenX || window.screenLeft || 0;
	const winRight = winLeft + winWidth;

	const win = {
		t: winTop,
		r: winRight,
		b: winBottom,
		l: winLeft,
		w: winWidth,
		h: winHeight,
	};

	const xoverlap_win = Math.max(
		0,
		Math.min(nodeRect.right, winWidth) - Math.max(nodeRect.left, 0)
	);
	const yoverlap_win = Math.max(
		0,
		Math.min(nodeRect.bottom, winHeight) - Math.max(nodeRect.top, 0)
	);
	const xoverlap = Math.min(nodeRect.width, xoverlap_win);
	const yoverlap = Math.min(nodeRect.height, yoverlap_win);
	const xiv = nodeRect.width ? xoverlap / nodeRect.width : 0;
	const yiv = nodeRect.height ? yoverlap / nodeRect.height : 0;
	const iv = (xoverlap * yoverlap) / (nodeRect.width * nodeRect.height);

	const self = {
		t: nodeRect.top + scrollY,
		r: nodeRect.right + scrollX,
		b: nodeRect.bottom + scrollY,
		l: nodeRect.left + scrollX,
		z: style.zIndex,
		w: nodeRect.width,
		h: nodeRect.height,
		xiv: xiv === 1 ? '1' : Number(xiv).toFixed(2),
		yiv: yiv === 1 ? '1' : Number(yiv).toFixed(2),
		iv: iv === 1 ? '1' : Number(iv).toFixed(2),
	};

	const exp = {
		t: Math.max(0, nodeRect.top),
		r: Math.max(0, winWidth - nodeRect.right),
		b: Math.max(0, winHeight - nodeRect.bottom),
		l: Math.max(0, nodeRect.left),
		xs: document.body.clientWidth > winWidth,
		yx: document.body.clientHeight > winHeight,
	};

	return {
		win,
		self,
		exp,
	};
};

// Function to post message to iframe
const postAwxSafeFrameMessage = (safeFrame, payload = {}) => {
	const message = JSON.stringify({
		...payload,
		namespace: 'awxsafeframe',
	});
	safeFrame.source.postMessage(message, safeFrame.domain);
};

// Updates safeframe on scroll
const updateSafeFrames = throttle(() => {
	Object.keys(safeFrames).forEach((adId) => {
		const safeFrame = safeFrames[adId];
		postAwxSafeFrameMessage(safeFrame, {
			cmd: 'geom-update',
			geom: getGeom(safeFrame.iframe),
		});
	});
}, 1000);
window.addEventListener('scroll', updateSafeFrames, { passive: true });

// Listen for messages from safeframes
const safeFrames = {};
window.addEventListener(
	'message',
	(event) => {
		let data;
		try {
			data = JSON.parse(event && event.data);
		} catch (err) {
			// continue regardless of error
		}

		if (!data || data.namespace !== 'awxsafeframe') {
			return;
		}

		const iframe = document.querySelector(`[name="${data.adId}"]`);
		const safeFrame = safeFrames[data.adId] || {
			iframe,
			source: event.source,
			domain: data.safeFrameDomain,
			initialWidth: parseInt(iframe.getAttribute('width')),
			initialHeight: parseInt(iframe.getAttribute('height')),
		};

		let cmd = 'geom-update';

		switch (data.type) {
			case 'init':
				safeFrames[data.adId] = safeFrame;
				break;
			case 'expand': {
				const { t = 0, r = 0, b = 0, l = 0 } = data.payload;
				const height = safeFrame.initialHeight + parseInt(t) + parseInt(b);
				const width = safeFrame.initialWidth + parseInt(l) + parseInt(r);

				Object.assign(safeFrame.iframe.parentNode.style, {
					position: 'relative',
					width: width + 'px',
					height: height + 'px',
				});

				Object.assign(safeFrame.iframe.style, {
					position: 'absolute',
					width: width + 'px',
					height: height + 'px',
					top: -parseInt(t) + 'px',
					left: -parseInt(l) + 'px',
				});

				cmd = 'expand';
				break;
			}
			case 'collapse': {
				const width = safeFrame.initialWidth + 'px';
				const height = safeFrame.initialHeight + 'px';

				Object.assign(safeFrame.iframe.parentNode.style, { width, height });
				Object.assign(safeFrame.iframe.style, {
					width,
					height,
					position: 'relative',
					top: '0px',
					left: '0px',
				});

				cmd = 'collapse';
				break;
			}
		}

		postAwxSafeFrameMessage(safeFrame, {
			cmd,
			geom: getGeom(safeFrame.iframe),
		});
	},
	false
);
