// RW-1079: Record when each ad slot appears in viewport
import Cookies from 'js-cookie';

const { clientWidth } = document.documentElement;
const platform = clientWidth < 768 ? 'm' : 'w';
const SLOTS_MAP = {
	top: 't',
	top_right: 'tr',
	native: 'n',
	infeed: 'i',
	bottom: 'b',
	bottom_right: 'br',
	middle: 'm',
	adhesion: 'a',
};
const adsInView = {};

const adDivObserver = new IntersectionObserver(
	(entries) => {
		entries.forEach((entry) => {
			const adDiv = entry.target;

			if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
				if (!('timeoutId' in adDiv.dataset)) {
					// Set a 1s timeout for viewable impression
					adDiv.dataset.timeoutId = setTimeout(() => {
						// Track viewable ads in cookie to be sent to ad server on next visit
						const slotName = SLOTS_MAP[adDiv.getAttribute('id')];
						saveToCookie(slotName);

						delete adDiv.dataset.timeoutId;
						adDivObserver.unobserve(adDiv);
					}, 1000);
				}
			} else {
				// If ad div goes below 50% viewable, clear timeout
				if (adDiv.dataset.timeoutId) {
					clearTimeout(adDiv.dataset.timeoutId);
					delete adDiv.dataset.timeoutId;
				}
			}
		});
	},
	{ threshold: 0.5 }
);

// Use different cookie name for homepages because
// using path='/' will apply the cookie for all pages
const cookieName = window.location.pathname.length < 4 ? 'awx_aiv_home' : 'awx_aiv';
const expires = new Date();
expires.setDate(expires.getDate() + 10);

const saveToCookie = (slotName) => {
	if (!slotName) return;

	const page = window.location.hash.replace('#', '') || 'home';
	const pageAiv = adsInView[page] || [];
	pageAiv.push(`${platform}${slotName}`);
	adsInView[page] = pageAiv;

	const cookieStr = convertJsonToString(adsInView);
	Cookies.set(cookieName, cookieStr, {
		expires,
		path: '',
	});
};

const convertJsonToString = (obj) => {
	const resultParts = [];

	for (let key in obj) {
		let values = obj[key].join(',');
		resultParts.push(`${key}:${values}`);
	}

	return resultParts.join('|');
}

export const trackAdDiv = (adDivEl) => {
	if (adDivEl) {
		adDivObserver.observe(adDivEl);
	}
};

window.addEventListener('adsReload', () => {
	const page = window.location.hash.replace('#', '') || 'home';
	adsInView[page] = [];
	adDivObserver.disconnect();
});
